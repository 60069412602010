// consumer_trains_product_getOrderInsuranceInfo 获取订单页保险信息
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.13:8765/doc.html#/haolv-consumer/product-controller/getOrderInsuranceInfoUsingPOST
const consumer_trains_product_getOrderInsuranceInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/product/getOrderInsuranceInfo',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_product_getOrderInsuranceInfo;