// consumer_tOdHotelOrderInterim_del 删除酒店行程
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/delUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_tOdHotelOrderInterim_del = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/del',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_tOdHotelOrderInterim_del;