// trains_product_getAlternativeTrainCheck 抢票备选车次不能勾选超标
// http://219.128.52.2:18765/doc.html#/yinzhilv-tmc/product-controller/getAlternativeTrainCheckUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_getEvectionDetail = (pParameter) => {
    if (!pParameter) pParameter = {};

    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/product/getAlternativeTrainCheck',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_getEvectionDetail;
